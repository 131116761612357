
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/model';
import { ExhibitionClient } from '@/services/services';

@Options({
    components: {
    }
})
export default class SearchByTitleResults extends Vue {

    exhibitionItems: OM.ExhibitionItemResumeVm[] = [];
    exhibitionIdentifier: string = StorageServices.getExhibition().identifier;
    searchText: string = "";
    exhibition: OM.GetExhibitionVm = StorageServices.getExhibition();

    created() {
        this.searchText = this.$route.params.searchText.toString();
        ExhibitionClient.getArtworksBySearchTitle(this.exhibitionIdentifier, this.searchText)
        .then(x => {
            this.exhibitionItems = x;
        })
    }

    searchForOpera(pin: string) {
        this.$router.push('/exhibition-item/' + pin);
    }
    
}
